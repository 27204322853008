<template>
  <!-- <header class="container-fuild login-header-section">
    <div class="row">
      <div class="header-logo">
        <img src="/assets/images/logo.png" />
      </div>
    </div>
  </header> -->
  <router-view />
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style lang="css">
@import url("/assets/css/style.css");
@import url("/assets/css/responsive.css");
</style>
